































































































































































































































































































































import Vue from 'vue';
import StatusTag from '@/components/media/CommissionStatusTag.vue';
import sleep from '@/util/sleep';
import { removeResourceById, Resource } from '@/util/list';

interface FakeEvent extends Resource {
  id: string,
  type: string,
}

export default Vue.extend({
  components: {
    StatusTag,
  },
  data() {
    return {
      events: [] as FakeEvent[],
      loading: false,
      status: 'requested',
      paid: [] as string[],
      counter: 0,
      uploading: false,
      willApprove: false,
      triedOut: [] as string[],
    };
  },
  methods: {
    async reject() {
      this.loading = true;

      await sleep(500);

      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'rejected',
      });
      this.status = 'rejected';

      this.loading = false;
    },
    async accept() {
      this.loading = true;

      await sleep(500);

      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'accepted',
      });
      this.status = 'active';

      this.loading = false;
    },
    async pending() {
      this.loading = true;

      await sleep(500);

      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'pending',
      });
      this.status = 'pending';

      this.loading = false;
      this.triedOut.push('pending');
    },
    async active() {
      this.loading = true;

      await sleep(500);

      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'active',
      });
      this.status = 'active';

      this.loading = false;
    },
    async complete() {
      this.loading = true;

      await sleep(500);

      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'complete',
      });
      this.status = 'complete';

      this.loading = false;

      window.setTimeout(() => {
        let tried = 0;
        if (this.triedOut.includes('file')) tried += 1;
        if (this.triedOut.includes('message')) tried += 1;
        if (this.triedOut.includes('item')) tried += 1;
        if (this.triedOut.includes('pending')) tried += 1;

        if (this.status === 'complete' && (this.willApprove || tried > 2)) {
          this.counter += 1;
          this.events.push({
            id: this.counter.toString(),
            type: 'approved',
          });
          this.status = 'finished';
        } else if (this.status === 'complete') {
          this.counter += 1;
          this.events.push({
            id: this.counter.toString(),
            type: 'disapproved',
          });
          this.status = 'active';
          this.willApprove = true;
        }
      }, 2500);
    },
    async message() {
      this.loading = true;

      await sleep(500);

      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'message-sent',
      });

      this.loading = false;
      this.triedOut.push('message');

      window.setTimeout(() => {
        this.counter += 1;
        this.events.push({
          id: this.counter.toString(),
          type: 'message-received',
        });
      }, 1500);
    },
    async upload() {
      this.loading = true;
      this.uploading = true;

      await sleep(1750);

      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'uploaded',
      });

      this.uploading = false;
      this.loading = false;
      this.triedOut.push('file');
    },
    async deleteFile(eventId: string) {
      this.loading = true;

      await sleep(500);

      removeResourceById(this.events, eventId);

      this.counter += 1;
      this.events.push({
        id: this.counter.toString(),
        type: 'deleted',
      });

      this.loading = false;
    },
    async item() {
      this.loading = true;

      await sleep(500);

      this.counter += 1;
      const id = this.counter.toString();
      this.events.push({
        id: this.counter.toString(),
        type: 'item',
      });

      this.loading = false;
      this.triedOut.push('item');

      window.setTimeout(() => {
        this.paid.push(id);
        this.counter += 1;
        this.events.push({
          id: this.counter.toString(),
          type: 'paid',
        });
      }, 3000);
    },
    async restart() {
      this.events = [];
      this.counter = 0;
      this.paid = [];
      this.willApprove = false;
      this.triedOut = [];
      this.status = 'requested';
    },
    getSvgComponentNameForEventType(type: string): string {
      let component;
      switch (type) {
        case 'accepted':
          component = 'Check';
          break;

        case 'rejected':
        case 'disapproved':
          component = 'XCircle';
          break;

        case 'uploaded':
          component = 'UploadCloud';
          break;

        case 'deleted':
          component = 'FileMinus';
          break;

        case 'item':
          component = 'PlusCircle';
          break;

        case 'paid':
          component = 'Unlock';
          break;

        case 'message-sent':
        case 'message-received':
          component = 'MessageCircle';
          break;

        case 'complete':
        case 'approved':
          component = 'CheckCircle';
          break;

        case 'active':
        case 'pending':
        default:
          component = 'InfoCircle';
          break;
      }

      return `Svg${component}`;
    },
  },
});
